@font-face {
    font-family: openSans;
    src: url('./assets/fonts/OpenSans-CondBold.ttf');
}

@font-face {
    font-family: futura;
    src: url('./assets/fonts/Futura Book font.ttf');
}


/*<----------------------General Rules----------------------------------->*/
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.font-15 {
    font-size: 15px;
}

.font-21 {
    font-size: 21px;
}

.font-28 {
    font-size: 28px;
}

.font-14 {
    font-size: 14px;
}

.bg-theme-color {
    background-color: #ff931e;
}

.font-openSans {
    font-family: openSans, sans-serif;
}

.font-futura{
    font-family: futura,sans-serif;
}

.btnOne {
    max-width: 100%;
    color: #e9e9e9;
    background-color: #ff931e;
    min-height: 3.125rem;
    line-height: 2.2;
    padding: 5px 12px;
    border: none;
    border-radius: 3px;
    letter-spacing: 1px;
    font-size: 14px;
}


.btnOne:hover {
    background-color: #ea7a00;
}

.btnTwo {
    max-width: 100%;
    color: #ff931e;
    background-color: transparent;
    min-height: 3.125rem;
    line-height: 2.2;
    padding: 2px 8px;
    border: 1px solid #ff931e;
    border-radius: 3px;
    letter-spacing: 1px;
    font-size: 12px;
}

.btnTwo:hover{
    background-color: #ff931e;
    color: white;
}

.removeBtn{
    background-color:  #FF3A3A;
    color: white;
    border: none;
}

.removeBtn:hover{
    background-color: transparent;
    border: 1px solid #FF3A3A;
    color: black;
}


/*<----------------------General Rules----------------------------------->*/

/*<----------------------Loader----------------------------------->*/
.loader-main{
    width: 100vw;
    height: 100vh;
}

.custom-loader {
    width:50px;
    height:50px;
    border-radius:50%;
    border:8px solid;
    border-color: #ff931e #0000;
    animation:s1 1s infinite;
}
@keyframes s1 {to{transform: rotate(.5turn)}}
/*<----------------------Loader----------------------------------->*/

/*<----------------------HomePage----------------------------------->*/
.homepage-about{
    padding: 55px 0 55px 0;
}
.homepage-about a{
    text-decoration: none;
    color: black;
}

.homepage-brand{
    padding: 55px 0 55px 0;
}

.homepage-brand-img img {
    max-width: 200px;
    max-height: 200px;
}

.homepage-category-img img{
    max-width: 350px;
    max-height: 350px;
}

.optics-img{
    width: 530px;
    height: 530px;
}

.text-over-image{
    position: relative;
    text-align: center;
    color: white;
}
.bottom-left {
    position: absolute;
    top: 40%;
    left: 70%;
}

@media only screen and (max-width: 1160px) {
    .homepage-category-img img{
        max-width: 250px;
        max-height: 250px;
    }
    .bottom-left {
        top: 35%;
        left: 60%;
    }
}
@media only screen and (max-width: 1024px) {
    .homepage-category-img img{
        max-width: 220px;
        max-height: 220px;
    }
    .homepage-brand-img img {
        max-width: 160px;
        max-height: 160px;
    }
}

@media only screen and (max-width: 768px) {
    .homepage-category-img img{
        max-width: 180px;
        max-height: 180px;
    }
    .homepage-brand-img img {
        width: 100px;
        height: 100px;
    }
    .optics-img{
        width: 400px;
        height: 400px;
    }
    .bottom-left {
        top: 25%;
        left: 60%;
    }
}

@media only screen and (max-width: 425px) {
    .optics-img{
        width: 300px;
        height: 300px;
    }
    .bottom-left {
       display: none;
    }
}



/*<----------------------HomePage----------------------------------->*/
/*<----------------------NAV----------------------------------->*/

.nav-sec {
    padding: 20px 80px;
    margin-top: 50px;
    border-bottom: 1px solid #ff931e;
}

.cart-icon:hover {
    cursor: pointer;
    transform: scale(1.1);
}

.links {
    text-decoration: none;
    color: black;
    letter-spacing: 1px;
    font-size: 18px;
    transition: color 0.3s, background-color 0.3s;
    font-weight: bold;
}

.links:hover,
.active-Link {
    text-decoration: none;
    color: white;
    background-color: #ff931e;
    letter-spacing: 1px;
    font-size: 16px;
    border-bottom: 2px solid transparent;
    padding: 5px;
    border-radius: 5px;
}


.links:hover,
.active-Link {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}


.mobile-view-links {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    background-color: #e9e9e9;
    padding: 15px;
}

.search-input {
    color: #000;
    font-size: 16px;
    line-height: 24px;
    width: 270px;
    padding: 8px 32px 8px 36px;
    border: 0;
    border-bottom: 1px solid #e9e9e9;
}

.search-input:focus {
    outline: none;
}

.toggle-btn {
    position: absolute;
    top: 6.0rem;
    right: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 31px;
    height: 19px;
    border: none;
    background-color: white;
}

.toggle-btn .bar {
    height: 3px;
    width: 100%;
    background-color: #ff931e;
    border-radius: 10px;
}

@media only screen and (max-width: 1024px) {
    .links {
        font-size: 15px;
    }
}

@media only screen and (max-width: 767px) {
    .toggle-btn{
        top: 6.7rem;
        right: 1rem;
        width: 27px;
        height: 15px;
    }
    .cart-icon{
        margin-left: 125px;
    }
}

@media only screen and (max-width: 684px) {
    .cart-icon{
        margin-left: 95px;
    }
    .toggle-btn{
        top: 5.8rem;
        right: 1rem;
    }
    .nav-sec img {
        width: 200px !important;
    }
}
@media only screen and (max-width: 559px) {
    .cart-icon{
        margin-left: 75px;
    }
    .toggle-btn{
        top: 5.8rem;
        right: 1rem;
    }
    .nav-sec img {
        width: 200px !important;
    }
}

@media only screen and (max-width: 425px) {
    .nav-sec {
        padding: 0;
        margin-top: 50px;
    }

    .nav-sec img {
        width: 200px !important;
    }
    .cart-icon{
        margin-left: 0px;
    }
    .toggle-btn{
        top: 5.9rem;
        right: 1rem;
        width: 27px;
        height: 15px;
    }
}

@media only screen and (max-width: 406px) {
    .toggle-btn{
        top: 7.1rem;
        right: 1rem;
        width: 27px;
        height: 15px;
    }
}


@media only screen and (max-width: 405px) {
    .toggle-btn{
        top: 6.9rem;
        right: 1rem;
        width: 24px;
        height: 14px;
    }
    .nav-sec img {
        width: 100px;
    }
}


/*<----------------------NAV----------------------------------->*/
/*<----------------------Banner----------------------------------->*/
.banner-sec {
    margin-top: 15px;
    padding-bottom: 55px;
}

.banner-sec ol li {
    list-style: none;
}

.carousel-caption {
    position: absolute;
    top: 210px;
    left: 54rem;
}

.carousel-caption span {
    text-shadow: 0 0 4px rgba(0, 0, 0, 0.4);
    font-size: 20px;
}

.carousel-caption h2 {
    color: #ff931e;
    font-size: 38px;
}


@media only screen and (max-width: 1440px) {
    .carousel-caption {
        top: 150px;
        left: 45rem;
    }
}

@media only screen and (max-width: 1024px) {
    .carousel-caption {
        top: 50px;
        left: 27rem;
    }
}

@media only screen and (max-width: 768px) {
    .carousel-caption {
        top: 10px;
        left: 12rem;
    }
}

@media only screen and (max-width: 425px) {
    .carousel-caption {
        display: none;
    }
}

@keyframes slide-in {
    from {
        opacity: 0;
        transform: translateY(-100px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.carousel-caption {
    animation: slide-in 2s ease-in-out;
}

/*<----------------------Banner----------------------------------->*/
/*<----------------------ProductCard----------------------------------->*/
.product-card{
    width: 30%;
    transition: transform 0.3s ease, transition-delay 0.1s;
}

.product-card:hover{
    cursor: pointer;
    transform: scale(1.05);
    transition-delay: 0s
}

.image-container {
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    position: relative;
    overflow: hidden;
}
.image-container img{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: scale(0.7);
}
.product-card-content{
    margin-top: auto;
    padding: 10px 0;
    text-align: start;
}

@media only screen and (max-width: 425px) {
    .product-card{
        flex: 0 0 40%;
    }
    .product-card h6{
        overflow: hidden;
        white-space: nowrap;
        text-overflow:ellipsis;
    }
}
/*<----------------------ProductCard----------------------------------->*/
/*<----------------------ProductItemPage----------------------------------->*/

.product-item-page{
    margin: 150px 0;
}

.product-item-page ul li {
   margin: 1px 0 0 0;
}
/*<----------------------ProductItemPage----------------------------------->*/

/*<----------------------ProductPage----------------------------------->*/

.product-list-page{
    margin: 100px 0;
}

.product-list-banner{
    color: white;
    padding: 55px;
    letter-spacing: 6px;
    background: linear-gradient(90.21deg, #f7b733 -5.91%, #fc4a1a 111.58%);
}
/*<----------------------ProductPage----------------------------------->*/
/*<----------------------BrandsPage----------------------------------->*/
.brands-page{
    margin: 100px 0;
}

.proj-imgbx {
    width: 200px;
    height: 200px;
    margin: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}
.proj-imgbx img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.proj-imgbx:hover{
    cursor: pointer;
}


.proj-imgbx::before {
    content: "";
    background: linear-gradient(90.21deg, #f7b733 -5.91%, #fc4a1a 111.58%);
    opacity: 0.85;
    position: absolute;
    width: 100%;
    height: 0;
    border-radius: 20%;
    transition: 0.4s ease-in-out;
}
.proj-imgbx:hover::before {
    height: 100%;
}
.proj-txtx {
    position: absolute;
    text-align: center;
    top: 65%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: 0.5s ease-in-out;
    opacity: 0;
    width: 100%;
}
.proj-imgbx:hover .proj-txtx {
    top: 50%;
    opacity: 1;
}
.proj-txtx h4 {
    color: white;
    font-size: 30px;
    font-weight: 700;
    letter-spacing: 0.8px;
    line-height: 1.1em;
}
/*<----------------------BrandsPage----------------------------------->*/

/*<----------------------CartPage----------------------------------->*/
.cart-main{
    margin-top: 100px;
    margin-bottom: 100px;
}

.check-out-btn{
    -webkit-font-smoothing: antialiased;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    /*background-color: transparent;*/
    cursor: pointer;
    display: inline-block;
    /*line-height: 1;*/
    text-align: center;
    text-decoration: none;
    user-select: none;
    vertical-align: middle;
    /*color: #000;*/
    font-size: 16px;
    line-height: 24px;
    font-family: Helvetica-Now-Text-Bold,Helvetica,Arial,sans-serif;
    font-weight: 700;
    background-color: #070b21;
    border: 0;
    border-radius: 2px;
    text-transform: none;
    color: #fff;
    width: 100%;
    padding: 12px 16px;
    transition: background-color .25s cubic-bezier(.25,.46,.45,.94);
    height: 90%;
}

.check-out-btn:hover{
    background-color: #403e3f;
}

.drawer-btn{
    width: 70%;
}

.payment-button-google{
    border: none;
    padding: 0;
    box-sizing: border-box;
    min-height: 32px;
    max-height: 64px;
    width: 100%;
    height: 48px;
}

.payment-button-paypal{
    border: 1px solid black;
    border-radius: 2px;
    padding: 0;
    box-sizing: border-box;
    min-height: 32px;
    max-height: 64px;
    width: 100%;
    height: 48px;
    background-size: 100% 25px;
}
/*<----------------------CartPage----------------------------------->*/
/*<----------------------TestimonialCard----------------------------------->*/

.testimonial-sec{
    padding: 55px 0 55px 0;
}

.testimonial-card{
    padding: 10px;
    border-radius: 5px;
    transition: transform 0.3s ease, transition-delay 0.1s;
}
.testimonial-card:hover{
    cursor: pointer;
    transform: scale(1.05);
    transition-delay: 0s
}
/*<----------------------TestimonialCard----------------------------------->*/
/*<----------------------Newsletter----------------------------------->*/

.newsletter-sec{
    padding: 55px 0 55px 0;
    background-color:  rgba(0,0,0,0.05);
}

.newsletter-sec input{
    border-radius: 2px 0 0 2px;
    border: 1px solid #ff931e;
    padding: 12px;
    width: 460px;
}
/*<----------------------Newsletter----------------------------------->*/

/*<----------------------CheckoutPage----------------------------------->*/
.checkOut-page{
    margin: 100px 0 248px 0;
}
/*<----------------------CheckoutPage----------------------------------->*/


/*<----------------------Footer----------------------------------->*/
.footer-sec{
    color: #e9e9e9;
    background-color: #808080;
}

.footer-sec a{
    text-decoration: none;
    color: white;
}

.footer-content{
    padding: 45px 0 30px 0;
}

.footer-content ul {
    font-weight: bold;
}

.footer-content ul li {
    list-style: none;
    padding-top: 5px;
    font-weight: bold;
    margin-top: 5px;
}


.footer-content ul li:hover {
    border-bottom: 1px solid #ff931e;
    width: 50%;
}
/*<----------------------Footer----------------------------------->*/

/*<----------------------FAQ----------------------------------->*/
.faq-section {
    padding: 5em 0;
    position: relative;
}


.faq-section button {
    width: 100%;
    text-align: left;
    font-family: Barlow, sans-serif;
    font-size: 1rem;
    line-height: 1;
    font-weight: bold;
    padding: 25px;
    margin: 0 0 1em 0;
    background: #f46b45;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to left, #eea849, #f46b45);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to left, #eea849, #f46b45); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    border-style: solid;
    border-width: 0;
    border-color: #00A15D70;
    border-radius: 5px 5px 5px 5px;
}

.faq-section h2 {
    font-size: 38px;
    line-height: 1;
}

.faq-section h6 {
    font-size: 14px;
    letter-spacing: 2px;
    color: #00A15D;
}

.faq-answer-area {
    border: 1px solid #eee;
    color: #333;
    padding: 2em 2em 2em 2em;
}
/*<----------------------FAQ----------------------------------->*/

/*<----------------------About Us----------------------------------->*/
.about-us-section{
    margin: 150px ;
}

/*<----------------------About Us----------------------------------->*/